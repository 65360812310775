<script setup>
import Footer from '@/Components/Footer/FooterLogin.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

// Components
import Button from '@/Components/Button/Button.vue';
import Icon from '@/Components/Icon.vue';

// Icon
import copyIcon from '@i/copy-icon-blue.svg';

const route = inject('route');
const props = defineProps({
    svg: String,
    secret: String,
    recoveryCodes: Array,
});

const form = useForm({
    code: '',
});

const sendForm = () => {
    form.post(route('user.two-factor.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

const copy = () => {
    navigator.clipboard.writeText(`${props.secret}`);
    const getInnerText = document.querySelector('.secret-button span');

    setTimeout(() => {
        getInnerText.innerHTML = 'Copied!';
        setTimeout(() => {
            getInnerText.innerHTML = props.secret;
        }, 1000);
    }, 100);
};
</script>

<template>
    <GuestLayout title="Log in">
        <div class="container relative flex flex-col items-center w-full -top-50">
            <div class="w-full p-8 bg-white rounded-2xl max-w-240 text-blue">
                <h1 class="text-[32px] font-bold mb-2">{{ $t('Set Two Factor Authentication') }}</h1>
                <div class="justify-between gap-8 md:flex">
                    <div class="md:w-1/2">
                        <p class="mb-6 text-base">
                            {{
                                $t(
                                    'To use Flexable Two Factor Authentication is required as extra security, you can set this now.'
                                )
                            }}
                        </p>

                        <h2 class="mb-3 text-lg font-bold">{{ $t('Download app') }}</h2>

                        <p
                            class="mb-6 text-base"
                            v-html="
                                $t(
                                    'On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.',
                                    {
                                        google: `<a href=&quot;https://googleauthenticator.net/&quot; target=&quot;_blank&quot; class=&quot;underline&quot;>${$t(
                                            'Google Authenticator'
                                        )}</a>`,
                                        microsoft: `<a href=&quot;https://www.microsoft.com/nl-nl/security/mobile-authenticator-app&quot; target=&quot;_blank&quot; class=&quot;underline&quot;>${$t(
                                            'Microsoft Authenticator'
                                        )}</a>`,
                                    }
                                )
                            "
                        ></p>

                        <h2 class="mb-3 text-lg font-bold">{{ $t('Scan the QR code') }}</h2>

                        <p class="mb-6 text-base">
                            {{ $t('Open the authenticator app, add a new entry and scan the QR code.') }}
                        </p>

                        <h2 class="mb-3 text-lg font-bold">{{ $t('Fill in the 6-digit code') }}</h2>

                        <FormKit
                            type="text"
                            v-model="form.code"
                            :value="form.code"
                            :label="$t('From the authenticator app')"
                            :errors="form.errors.code"
                        />

                        <Button :processing="form.processing" @click="sendForm">{{ $t('Verify') }}</Button>
                    </div>

                    <div
                        class="flex items-center justify-center p-4 text-base text-center border rounded-lg md:w-1/2 border-gray-border"
                    >
                        <div class="">
                            <span v-html="svg" class="flex justify-center"></span>
                            <p class="mt-6 mb-4">
                                {{ $t('Scanning not possible? Fill in this code in the app.') }}
                            </p>
                            <div class="flex justify-center">
                                <button
                                    @click="copy"
                                    ref="clone"
                                    :value="secret"
                                    readonly
                                    class="flex items-center gap-4 font-bold transition-colors duration-300 secret-button outline-0"
                                >
                                    <span>{{ secret }}</span>
                                    <Icon :icon="copyIcon" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </GuestLayout>
</template>
